<template>
    <div class="modal-container">
		<div class="modal" :class="{ 'modal--register': registerAndLarge }">
			<div class="modal__title">
				<button class="modal__back" v-if="registerLarge === false" @click="setRegisterLarge"><img src="@/assets/images/back.png" alt="back" width="20"></button>
				<h1>{{ modifier }} {{ type }}</h1> 
				<button class="modal__close" @click="closeModal">
					<img class="modal__close-image" src="@/assets/images/close.svg" alt="">
				</button>
			</div>
            <edit-inputs-container v-if="edit"/>
			<register-inputs-container @small="setRegisterSmall" @large="setRegisterLarge" :renderSelectItems="registerLarge" v-else-if="register"/>
			<create-inputs-container v-else-if="create"/>
			<multi-tab-inputs-container v-else-if="multi" />
			
		</div>
	</div>
</template>
<script>
import createInputsContainer from '@/components/Modals/CreateModals/InputsContainer.vue'
import editInputsContainer from '@/components/Modals/EditModals/inputsContainer.vue'
import registerInputsContainer from '@/components/Modals/RegisterModals/InputsContainer.vue'
import multiTabInputsContainer from '@/components/Modals/MultiTabModal/inputsContainer.vue'


export default {

	components:{ editInputsContainer,registerInputsContainer, createInputsContainer, multiTabInputsContainer },
	data(){
		return {
			registerLarge: true,
		}
	},
	methods:{
		setRegisterLarge(){
			console.log('large')
			this.registerLarge = true
		},
		setRegisterSmall(){
			console.log('small')
			this.registerLarge = false
		},
		closeModal(){
			
			this.$router.go(-1)
		}
	},

	computed:{
        modifier(){
            return this.$route.meta['M_TITLE'].modifier
        },
        type(){
            return this.$route.meta['M_TITLE'].type
        },
		edit(){
			return this.modifier === 'edit'
		},
		create(){
			return this.modifier === 'create' || this.modifier === 'add'
		},
		registerAndLarge(){
			return this.register && this.registerLarge
		},
		multi(){
			return this.modifier === 'sell'
		},
		register(){
			return this.modifier === 'register' || this.modifier === 'update' 
		},
	},
	mounted(){
		console.log('mounted')
	}

}
</script>
