<template>
    <div v-if="loading">loading</div>
    <div v-else class="modal__input-area">
        <template v-for="(value, name) in createInputs" :key="name">
            <div class="modal__input-container" v-if="value.type==='text'">
             
                <span class="modal__input-prefix" v-show="value.isPhone">+233</span>  
                <input :id="`mid-${name}`" v-model="createMetaData[name]" type="text" class="modal__input" 
                :class="{'modal__input--prefixed':value.isPhone}" placeholder="" :ref="firstInputRef()" />
              
                <label class="modal__label" :class="{'modal__label--prefixed':value.isPhone}" :for="`mid-${name}`">{{ value.substitute }}</label>
            </div>
            <div class="modal__input-container" v-else-if="value.type==='description'">
                <textarea :id="`mid-${name}`" v-model="createMetaData[name]"  class="modal__description" placeholder=" " type="text"></textarea>
                <label class="modal__label" :for="`mid-${name}`">{{ value.substitute }}</label>
            </div>
            <div class="modal__input-container" v-else-if="value.type==='select'">
                <select :id="`mid-${name}`"  v-model="createMetaData[name]" class="modal__input modal--options">
                    <option v-for="(inputOption,index) in inputOptions" :value="inputOption.id" :key="index">{{ inputOption.name }}</option>
                </select>
                <label class="modal__label" :for="`mid-${name}`">{{ value.substitute }}</label>
            </div>
            <image-upload :add="true" @get-image="setImage($event,name)" v-else-if="value.type==='image'" />
        </template>
        <div class="modal__buttons-container">
			<button @click="create" class="modal__button" :disabled="!isValidated">
                <div v-if="checking"><filling-circle :size="16" /></div>
                <div v-else style="text-transform:capitalize">{{ modifier }}</div>
            </button>
		</div>
    </div>
</template>

<script>
import FillingCircle from '@/components/Loaders/FillingCircle.vue'
import ImageUpload from '@/components/Modals/ModalInputs/ImageUpload.vue'
import { isEmpty } from '@/helpers/FormValidation.js'

export default {
    name:'create-input-container',
    components:{FillingCircle,ImageUpload},
    data(){
        return {
            loading:false,
            checking:false,
            createMetaData:{},
            firstInputSet:false,
            inputOptions:[],
        }
    },
    computed:{
        isValidated(){
            let validation =  true
            for( let input in this.createInputs ){
                if ( this.createInputs[input].validate){
                    if ( isEmpty(this.createMetaData[input]) || this.createMetaData[input] === undefined || this.createMetaData  === null ) validation = false 
                }
            }
            return validation
        },
        id(){
            return this.$route.params.id
        },
        modifier(){
            return this.$route.meta['M_TITLE'].modifier
        },
        name(){
           
            return this.$route.meta['M_TITLE'].type
        },

        createInputs(){
            return this.$route.meta['RENDER_INPUTS']
        },
        createType(){
            return this.$route.meta['M_REQUESTS'].create
        },
        loadInputOptionsType(){
            return this.$route.meta['M_REQUESTS'].loadInputOptions
        },
      
    },
    methods:{
        create(){
            this.checking = true
            this.$store.dispatch({
                type: this.createType,
                branch: this.id,
                ...this.createMetaData
            }).then(()=>{
                this.checking = false
                this.$router.go(-1)
                this.$notify({
                    title: `${this.name} created!`,
                    text: `you successfully created a ${this.name}`,
                    type:"success"
                })
            })
            .catch(error=>{
                this.checking = false
                this.$notify({
                    title: `Error creating ${this.name}!`,
                    text: error,
                    type:"error"
                })
                console.log(error.response)
            })
            
        },
        firstInputRef(){
            if ( !this.firstInputSet ){
                this.firstInputSet = true
                return 'firstInput'
            }
            return null
            
        },

        setImage(photo,name){
            this.createMetaData[name] = photo
        },
    },
    mounted() {
        this.$refs.firstInput.focus();
        if(this.loadInputOptionsType){
            this.loading = true
            this.$store.dispatch({
                type:this.loadInputOptionsType,
            }).then((response)=>{
                console.log(response)
                this.createMetaData['user_role'] = response[0].id 
                this.inputOptions = response
                this.loading = false
            }).catch((error)=>
            {
                this.loading = false
                console.log(error)
                this.$router.go(-1)
            })
        }
    }
}
</script>
<style>
    
    .modal__input-prefix{
        position:absolute;
        display: flex;
        align-items: center;
        padding:1em;
        top:0;
        bottom:0;
        border-right: solid 1px rgba(0, 0, 0, 0.1);
    }

    .modal__input--prefixed{
        padding-left: calc(2.25em + 4ch);
    }

    .modal__label--prefixed{
        left:calc(2.25em + 4ch);
    }
</style>