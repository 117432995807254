<template>

    <div class="modal__tab-area">
        <div class="modal__tabs"  v-show="canMakeSalesAndManageTabs">
            <div class="modal__tab" 
            @click="switchSections('first')"
            :class="{'modal__tab--active':firstSectionSelected}">Money</div>

            <div class="modal__tab" 
            @click="switchSections('second')"
            :class="{'modal__tab--active':secondSectionSelected}">credit</div>
        </div>

        <div  v-if="firstSectionSelected" class="modal__input-area">
           
           <div class="modal__input-container">
                <input id="multi-id-payable" type="text" class="modal__input" placeholder=" " :value="amountPayable" disabled/>
                <label class="modal__label" for="multi-id-payable">amount payable</label>
            </div>
            
            <div class="modal__input-container"> 
                <input for="multi-id-balance" type="text" class="modal__input" placeholder=" " :value="balance" disabled/>
                <label class="modal__label" for="multi-id-balance">balance</label>
            </div>
            
            <div class="payment">
                
                <!-- <div class="modal__input-container">
                    <input id="multi-id-received" type="text" class="modal__input" placeholder=" " @keypress="preventAlphabeth" v-model="cashAmount"/>
                    <label class="modal__label" for="multi-id-received">cash amount</label>
                </div> -->
                <div class="payment__methods">
                    
                    <select name="" id="" class="payment__type" v-model.number="firstPaymentMethod.payment_mode">
                        <option value="0" selected="selected">Cash</option>
                        <option value="1">Mobile Money</option>
                        <option value="2"> Card</option>
                        <option value="3">Bank Transfer</option>
                    </select>

                    <div class="modal__input-container payment__amount"> 
                        <input :id="`multi-first-received`" type="text" class="modal__input" placeholder=" " @keypress="preventAlphabeth" v-model.number="firstPaymentMethod.amount_received"/>
                        <label class="modal__label" :for="`multi-first-received`">Amount</label>
                    </div>
                    <div class="modal__input-container payment__id" v-show="firstPaymentMethod.payment_mode !== 0">
                        <input :id="`multi-first-id`" type="text" class="modal__input" placeholder=" " v-model="firstPaymentMethod.reference_id"/>
                        <label class="modal__label" :for="`multi-first-id`">Transaction Id</label>
                    </div>
                </div>

                <div class="payment__methods" v-for="(method,index) in paymentMethods" :key="index">
                    
                    <select name="" id="" class="payment__type" v-model="method.payment_mode">
                        <option value="1" selected="selected">Mobile Money</option>
                        <option value="2"> Card</option>
                        <option value="3">Bank Transfer</option>
                    </select>

                    <div class="modal__input-container payment__amount"> 
                        <input :id="`multi-${index}-morereceived`" type="text" class="modal__input" placeholder=" " @keypress="preventAlphabeth" v-model.number="method.amount_received"/>
                        <label class="modal__label" :for="`multi-${index}-morereceived`">Amount</label>
                    </div>
                    <div class="modal__input-container payment__id">
                        <input :id="`multi-${index}-moreid`" type="text" class="modal__input" placeholder=" " v-model="method.reference_id"/>
                        <label class="modal__label" :for="`multi-${index}-moreid`">Transaction Id</label>
                    </div>
                    <div class="payment__delete" @click="deletePaymentMethod(index)">x</div>
                </div>
                
                <div class="modal__buttons-container">
                    <button @click="addPaymentMethod" class="modal__button modal__button--outline">
                        + payment method
                    </button>
                </div>
            </div>


           
            
            

            
            <div class="modal__buttons-container">
                <button @click="sell" class="modal__button">
                    <div v-if="checking"><filling-circle :size="16" /></div>
                    <div v-else>Sell</div>
                </button>
            </div>

        </div>

        <div class="modal__credit-tab" v-else>
            <div class="modal__credit-container">
                 <input type="text" v-model="searchQuery" class="modal__input" placeholder="search" />     
            </div>
               
            <div class="modal__credit-list">
                <div v-if="loading" style="text-align:center">loading...</div>
                <div v-else >
                    <template v-for="(tab,index) in searchedTabs"  :key="index">
                        <div class="modal__selectable" :class="{'modal__selectable--selected':isSelectedTab(tab)}" @click="selectTab(tab)">
                            <span class="tab-name">{{ tab.name }} </span>
                            <span class="tab-credit">-{{ tab.total }}</span>
                        </div>
                    </template>
                </div>
                
            </div>
        
            <div class="modal__buttons-container">
                <button @click="credit" class="modal__button modal--credit-button">
                    <div v-if="checking"><filling-circle :size="16" /></div>
                    <div v-else >Add order to tab</div>
                </button>
            </div>
            
        </div>

        
    </div>
</template>


<script>
import FillingCircle from '@/components/Loaders/FillingCircle.vue'

export default {
    components:{FillingCircle},
    data(){
        return {
            searchQuery:'',
            firstPaymentMethod:{payment_mode:0,amount_received:0,reference_id:''},
            paymentMethods:[],
            firstSectionSelected:true, 
            selectedTab:{},
            checking:false,
            loading:true,
        }
    },
    computed:{
        id(){
            return this.$route.params.id
        },
        branchId(){
            return this.$store.state.branchId
        },

        allTabs(){
            return this.$store.state.credits.creditTabs
        },

        secondSectionSelected(){
            return !this.firstSectionSelected
        },

        otherAmounts(){
            let amount = 0
            for( let method of this.paymentMethods){
                amount += method.amount_received
            }

            return amount
        },

        amountReceived(){
            return this.firstPaymentMethod.amount_received + this.otherAmounts
        },

        balance(){       
            return this.firstPaymentMethod.amount_received + this.otherAmounts - this.amountPayable
        },


        salesPayment(){
            let payments = [this.firstPaymentMethod,...this.paymentMethods]
            return payments
        },

        amountPayable(){
			let amountPayable = this.$store.state.orders.orderDetails.amount_payable
			return amountPayable
        },

        searchedTabs(){
            return this.allTabs.filter( tab => tab.name.startsWith(this.searchQuery) )
        },
        canManageTabs(){
            let isVendor = this.$store.state.user.user_type === 'Vendor'
            return isVendor ? true : this.$store.state.user.user_role.can_manage_account_tabs
        },
        canManageTabsOnly(){
            let isVendor = this.$store.state.user.user_type === 'Vendor'
            return isVendor ? false : this.$store.state.user.user_role.can_manage_account_tabs && !this.$store.state.user.user_role.can_make_sales
        },
        canMakeSalesAndManageTabs(){
            let isVendor = this.$store.state.user.user_type === 'Vendor'
            if (isVendor) return true 
            else return this.$store.state.user.user_role.can_manage_account_tabs && this.$store.state.user.user_role.can_make_sales
        }
    },

    methods:{
        switchSections(tabClicked){
            if(!this.checking){
                if (tabClicked === 'first'){
                    this.firstSectionSelected = true
                }else{
                    this.firstSectionSelected = false
                }
            }
        },

        preventAlphabeth(evt){
            if (evt.which < 48 || evt.which > 57)
            {
                evt.preventDefault();
            }
        },

        selectTab(tab){
            this.selectedTab = tab
        },

        isSelectedTab(tab){
            return this.selectedTab === tab
        },

        addPaymentMethod(){
            this.paymentMethods.push({payment_mode:1, amount_received: 0, reference_id:''})
        },

        deletePaymentMethod(index){
            this.paymentMethods.splice(index,1)
        },

        sell(){
            this.checking = true
            this.$store.dispatch({
                type:'orders/sellOrder',
                id:this.id,
                amount_received: this.amountReceived,
                sale_payments: this.salesPayment
            })
            .then((response)=>{
                this.checking = false
                console.log(response)
                this.$router.push({name:'orders',params:{id:this.branchId} })

            }).catch(error=>{
                console.log(error)
                this.checking = false
            })
        },

        credit(){
            this.checking = true
            this.$store.dispatch({
                type:'credits/updateCreditTab',
                orderId:this.id,
                creditTabId:this.selectedTab.id,
            })
            .then((response)=>{
                this.$notify({
                    title: `New Order Added to ${this.selectedTab.name}`,
                    text:`Successfully added order to Tab!`,
                    type:"success"
                })
                this.checking = false
                console.log(response)
                this.$router.push({name:'orders',params:{id:this.branchId} })

            }).catch(error=>{
                this.$notify({
                    title: "Failed to add order to Tab",
                    text: error,
                    type:"error"
                })
                console.log(error)
                this.checking = false
            })
        }
    },

    mounted(){
        console.log()
        this.loading = true
        if(this.canManageTabsOnly) this.firstSectionSelected = false
        if(this.canManageTabs) this.$store.dispatch({
            type:'credits/loadTabs',
            id:this.$store.state.branchId,
        }).then((response)=>{
            this.loading = false
            console.log(response)
        }).catch(error=>{
            console.log(error)
        })
    }
}
</script>

<style>
 .modal__button--outline{
     color: var(--bg-dark);
     background: transparent;
     box-shadow: none;
     border: dashed 1px var(--bg-dark)
 }

  .modal__button--outline:hover{
     background: transparent;
 }



 .payment__methods{
     position: relative;
     display: flex;
     flex-wrap: wrap;
     padding:.75em;
     margin-bottom: 1em;
     background: rgb(247,249,250);
     border-radius: 6px;
 }

 .payment > .modal__input-container{
     margin-bottom:1em;
 }

.payment__amount{
     flex:3;
     margin-bottom:8px;
 }

 .payment__type{
     margin-right:1em;
     margin-bottom:8px;
 }

 .payment__id{
     width: 100%;
     margin-bottom:0;

 }

 .payment__delete{
     position: absolute;
     font-weight: bold;
     display: flex;
     align-items: center;
     justify-content: center;
     width: 24px;
     height: 24px;
     right: 0;
     top:0;
     color:white;
     background: red;
     padding:4px;
     transform: translate(40%,-40%);
     border-radius: 50%;
     opacity: .5;
 }

  .payment__delete:hover{
      cursor:pointer;
      opacity:.75;
      transition: .3s opacity ease;
  }

</style>