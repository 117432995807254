<template>
  <div v-if="renderSelectItems" class="registration">
    <div class="register">
      <div class="register__list-header">
        <div></div>
        <div class="register__list-name">Name</div>
        <div class="register__list-name">Price</div>
        <div class="register__list-name">Quantity</div>
        <div class="register__list-name">Tag</div>
      </div>
      <div v-if="registerList.length" class="register__list">
        <div v-for="(item, index) in registerList" :key="index" class="register__item">
          <button class="register__item-delete" @click="removeItem(item.id)">X</button>
          <div></div>
          <div class="register__list-value">
            {{ item.name }}
          </div>
          <div class="register__list-value">
            <input
              v-if="isSellingPrice"
              type="text"
              :placeholder="item.unit_minimum_selling_price"
              @blur="checkValidPrice(index)"
              @focus="inputFocus = true"
              v-model="item.unit_selling_price"
              :disabled="!canEditSellingPrice"
              class="register__list-price"
            />
            <span v-else>{{ item.unit_cost_price }}</span>
          </div>
          <div class="register__list-value">
            <input
              type="text"
              @blur="checkNumberInput(index)"
              v-model="item.quantity"
              @focus="inputFocus = true"
              class="register__list-quantity"
            />
          </div>
          <div class="register__list-value">
            <select class="register__list-quantity" @change="addTagToName($event, item)">
              <option value="" selected="selected">None</option>
              <option value="take away">Take Away</option>
            </select>
          </div>
        </div>
      </div>
      <div v-else class="register__list-empty">
        {{ modifier }} "Products" in your {{ type }}
      </div>
      <div class="register__buttons">
        <button @click="clearOrders" class="register__button register__button--danger">
          Clear
        </button>
        <button v-if="inputFocus" class="register__button register__button--verify">
          verify
        </button>
        <button
          v-else
          @mousedown="gotoRegister"
          class="register__button"
          :disabled="inputFocus"
        >
          <div v-if="submittingItems" style="display: flex; justify-content: center">
            <filling-circle :size="16" />
          </div>
          <div v-else>{{ modifier }} {{ type }}s</div>
        </button>
      </div>
    </div>

    <div class="register__select">
      <div class="register__filter-container">
        <div class="register__filters">
          <input
            type="text"
            @blur="addClickedState"
            @focus="removeClickedState"
            :placeholder="`search products`"
            v-model="searchQuery"
            class="modal__input modal__input--register"
          />
        </div>
      </div>
      <!-- select products-->
      <div
        class="register__select-list"
        :class="{ 'register__select-list--empty': hideSelectList }"
      >
        <div class="register__loading-products" v-if="loadingProducts">
          <filling-circle color="#057DCD" :size="42" />
        </div>
        <template v-else>
          <div
            @mousedown="selectOrder({ ...product, quantity: 1 })"
            v-for="(product, index) in searchedProducts"
            :key="index"
            class="register__select-item"
          >
            <div>{{ product.name }}</div>
            <div>
              GHC
              {{ isSellingPrice ? product.unit_minimum_selling_price : product.unit_cost_price }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>

  <div v-else class="modal__input-area">
    <template v-for="(value, name) in registerInputs" :key="name">
      <div class="modal__input-container" v-if="value.type === 'text'">
        <span class="modal__input-prefix" v-show="value.isPhone">+233</span>
        <input
          :id="`mid-${name}`"
          v-model="registerMetaData[name]"
          type="text"
          class="modal__input"
          :class="{ 'modal__input--prefixed': value.isPhone }"
          placeholder=" "
        />

        <label
          class="modal__label"
          :class="{ 'modal__label--prefixed': value.isPhone }"
          :for="`mid-${name}`"
          >{{ value.substitute }}</label
        >
      </div>

      <div class="modal__input-container" v-else-if="value.type === 'description'">
        <textarea
          id="register-id-description"
          v-model="registerMetaData[name]"
          class="modal__description"
          placeholder=" "
          type="text"
        ></textarea>
        <label class="modal__label" for="register-id-description">{{
          value.substitute
        }}</label>
      </div>
      <div class="modal__input-container" v-else-if="value.type === 'check'">
        <label class="modal__check-label"
          ><input
            class="modal__check"
            v-model="registerMetaData[name]"
            type="checkbox"
            name="isend"
            id=""
          />{{ value.substitute }}</label
        >
      </div>
    </template>

    <!-- <input v-model="name" class="modal__input" placeholder="register" type="text">
       <textarea v-model="description" class="modal__description" placeholder="Description" type="text"></textarea> -->
    <div class="modal__buttons-container">
      <button @click="submitRegister" class="modal__button">
        <div v-if="submittingItems"><filling-circle :size="16" /></div>
        <div v-else>Submit</div>
      </button>
    </div>
  </div>
</template>

<script>
import FillingCircle from "@/components/Loaders/FillingCircle.vue";
export default {
  components: { FillingCircle },
  props: {
    renderSelectItems: Boolean,
  },
  data() {
    return {
      registerList: [],
      allProducts: [],
      searchQuery: "",
      sentItems: [],
      registerMetaData: {},
      submittingItems: false,
      loadingProducts: true,
      clicked: false,
      inputFocus: false,
    };
  },
  computed: {
    branchId() {
      return this.$store.state.branchId;
    },
    id() {
      return this.$route.params.id;
    },
    loadItems() {
      return this.$store.dispatch({
        type: this.load,
        id: this.branchId,
      });
    },

    isSellingPrice() {
      return this.$route.meta["M_RESQUEST_DATA_FILTER"].productSellingPrice;
    },

    hideSelectList() {
      return this.searchQuery === "" || this.clicked;
    },
    registerType() {
      return this.$route.meta["M_REQUESTS"].register;
    },
    registerListName() {
      return this.$route.meta["M_RESPONSE_DATA_FILTER"].listName;
    },
    // registerItems(){
    //     return
    // },
    registerInputs() {
      return this.$route.meta["RENDER_INPUTS"];
    },
    type() {
      return this.$route.meta["M_TITLE"].type;
    },

    modifier() {
      return this.$route.meta["M_TITLE"].modifier;
    },
    load() {
      return this.$route.meta["M_REQUESTS"].load;
    },
    searchedProducts() {
      return this.allProducts.filter((product) =>
        product.name.toLowerCase().includes(this.searchQuery.toLowerCase().trim())
      );
    },
    validateItesAdd() {
      if (!Array.isArray(this.registerList)) return false;
      else if (this.registerList.length === 0) return false;
      else return true;
    },
    validateTextFields() {
      if (this.name && this.description) {
        return true;
      } else {
        return false;
      }
    },
    sentItemsFilter() {
      return this.$route.meta["M_RESQUEST_DATA_FILTER"].register;
    },
    canEditSellingPrice() {
      return this.isVendor
        ? true
        : this.$store.state.user.user_role.can_edit_prices_for_orders;
    },

    isVendor() {
      return this.$store.state.user.user_type === "Vendor";
    },
  },
  methods: {
    addTagToName(event, item) {
      let tag = event.target.value ? ` (${event.target.value})` : "";
      item.name = item.productName + tag;
    },

    selectOrder(item) {
      let isAlreadyAdded = false;
      let selectedKey;

      for (let key in this.registerList) {
        if (this.registerList[key].id === item.id) {
          isAlreadyAdded = true;
          selectedKey = key;
        }
      }

      if (isAlreadyAdded) this.registerList[selectedKey].quantity++;
      else {
        item.productName = item.name;
        this.registerList.push(item);
      }
    },
    clearOrders() {
      this.registerList = [];
    },
    gotoRegister() {    
        let objects = []
      
        for (const order of this.registerList) {
          let object = {};
          for (const filter of this.sentItemsFilter) {
            console.log(order[filter.name]);
            object[filter.substitute] = order[filter.name];
          }
          objects.push(object);
        }
      this.sentItems = objects
      if (this.registerInputs) {
        this.$emit("small");
      } else {
        this.submitRegister();
      }
    },
    submitRegister() {
      this.submittingItems = true;

      this.$store
        .dispatch({
          type: this.registerType,
          branch: this.branchId,
          id: this.id,
          [this.registerListName]: this.sentItems,
          ...this.registerMetaData,
        })
        .then(() => {
          this.submittingItems = false;
          this.$notify({
            title: `${this.type} were ${this.modifier}d `,
            text: `Successfully added order to Tab!`,
            type: "success",
          });
          this.$router.go(-1);
        })
        .catch((error) => {
          this.submittingItems = false;
          this.$notify({
            title: "Failed to register ",
            text: error,
            type: "error",
          });
          this.$emit("large");
        });
    },
    removeItem(id) {
      console.log(this.registerList);
      this.registerList = this.registerList.filter(
        (registerItem) => registerItem.id !== id
      );
    },
    changeQuantity(id, delta) {
      for (const item of this.registerList) {
        if (item.id == id) {
          item.quantity = Number(item.quantity) + (item.quantity + delta > 0 ? delta : 0);
          break;
        }
      }
    },
    addClickedState() {
      this.clicked = true;
    },
    removeClickedState() {
      this.clicked = false;
    },
    checkNumberInput(index) {
      //refactor : this checks if the number entered into quanty is not white space empty not a number or less than one
      if (
        isNaN(this.registerList[index].quantity) ||
        (!/\S/.test(this.registerList[index].quantity) &&
          /\s/g.test(this.registerList[index].quantity)) ||
        this.registerList[index].quantity < 1
      ) {
        this.$notify({
          title: "Invalid Product Quantity",
          text: "Product Quantity value is not allowed, please try again",
          type: "error",
        });
        this.registerList[index].quantity = 1;
      }
      this.inputFocus = false;
    },
    checkValidPrice(index) {
    if (
        isNaN(this.registerList[index].unit_selling_price) ||
        (!/\S/.test(this.registerList[index].unit_selling_price) && /\s/g.test(this.registerList[index].unit_selling_price)) || 
        (this.registerList[index].unit_selling_price === '') ||
        (this.registerList[index].unit_selling_price < 0) ||
        (this.registerList[index].unit_selling_price <= this.registerList[index].unit_minimum_selling_price && !this.isVendor)) {
        this.$notify({
          title: "Invalid Selling Price",
          text: "Selling price value is not allowed, please try again",
          type: "error",
        });
        this.registerList[index].unit_selling_price = this.registerList[
          index
        ].unit_minimum_selling_price;
      }

      this.inputFocus = false;
    },
  },
  mounted() {
  
    

    this.loadItems
      .then((response) => {
        if(this.registerInputs.comment){
          if( this.registerInputs.comment.persistence ) {
            const comment = this.$store.state.orders.orderDetails.comment
            this.registerMetaData['comment'] =  comment ? comment : ''
          }
        }
        
        this.loadingProducts = false;
        this.allProducts = response;
      })
      .catch((error) => console.log(error));
  },
};
</script>
