<template>
    <div class="modal__input-area">
        <div v-if="loading" class="modal__loading">
            <filling-circle :size="42" color="#057DCD"/>
        </div>
		<div v-else>
            <!--delete Section -->
            <div v-if="deleteTab">
                <div class="modal__question"> Are sure you want to delete?</div>
                <div class="modal__buttons-container">
                    <button @click="deleteItem" class="modal__button modal__button--delete" >
                        <div v-if="deleting"><filling-circle :size="16" /></div>
                        <div style="padding-left:1em;">Delete</div>
                    </button>
                </div>
            </div>
            <!-- edit section -->
            <div v-else>

                <edit-box v-for="(filterData,filter) in inputFilters" :key="filter" :name="filterData.substitute" :requestKey="filter" 
                :type="filterData.type" :defaultValue="data[filter]" :isPhone="filterData.isPhone" :id="id" :options="data[filterData.options]"/>
                <div class="modal__buttons-container">
                    <button class="modal__button modal__button--delete" @click="openDelete">delete</button>
                </div>
            </div>
            
        </div>
		
	</div>
</template>
<script>
import EditBox from '@/components/Modals/ModalInputs/EditBox.vue'
import FillingCircle from '@/components/Loaders/FillingCircle.vue'
export default {
    components:{ EditBox, FillingCircle },
	data(){
        return {
            data:{},
            loading:false,
            deleting:false,
            deleteTab:false,
        }

    },
	methods:{

        openDelete(){
            console.log('delete')
            this.deleteTab=true
        },

        setImage(image){
           this.image = image

        },
        deleteItem(){
            this.deleting = true
            let id  = (this.previousRoute === 'Product-Category') ? this.$store.state.products.productDetails.category : this.branchId

            this.deleteInstance.then((response)=>{
                this.deleting = false
                
                this.$router.push({ name:this.previousRoute,params:{id} })
                console.log(response)
            }).catch(error=>{
                this.deleting = false
                console.log(error)
            })
        } 
        
	},
    computed:{
        id(){
            return this.$route.params.id
        },
        branchId(){
            return this.$store.state.branchId
        },
        previousRoute(){
            return this.$route.meta['OPTIONS'].back
        },
        loadType(){
            return this.$route.meta['M_REQUESTS'].load
        },
        deleteType(){
            return this.$route.meta['M_REQUESTS'].delete
        },
        deleteInstance(){
            return this.$store.dispatch({ type:this.deleteType, id:this.id })
        },
        loadData(){
			return this.$store.dispatch({ type:this.loadType, id:this.id })
		},
        inputFilters(){
            return this.$route.meta['RENDER_INPUTS']
        }
    },
	mounted(){
        this.loading = true
        this.loadData.then((response)=>{
            this.loading = false
            this.data = response
            console.log(response)
        }).catch(error=>{
            this.loading = false
            console.log(error)
        })
    }
    
}
</script>
